#contact .title {
    margin-bottom: 3rem;
}

.contact-form {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}