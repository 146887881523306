#classes {
  padding-top: 4rem;
  background-color: var(--color-light);
}

.class-card {
  max-width: 80%;
  overflow: visible !important;
  margin: 3rem auto;
  padding: 1rem;
  box-shadow:
    0.6px 0.6px 2.2px rgba(105, 168, 141, 0.141),
    1.3px 1.3px 5.3px rgba(105, 168, 141, 0.202),
    2.5px 2.5px 10px rgba(105, 168, 141, 0.25),
    4.5px 4.5px 17.9px rgba(105, 168, 141, 0.298),
    8.4px 8.4px 33.4px rgba(105, 168, 141, 0.359),
    20px 20px 80px rgba(105, 168, 141, 0.5)
    !important;
}