@import '~react-image-gallery/styles/css/image-gallery.css';

#gallery {
    padding: 0;
}

.image-gallery-slide-wrapper {
    background-color: var(--color-light-alt);
}

.image-gallery-image {
    height: calc(100vh - 9rem) !important;
}

.image-gallery-description {
    left: 50% !important;
    bottom: 24px !important;
    padding: 0 !important;
    transform: translateX(-50%);
    font-family: 'Roboto','Helvetica','Arial',sans-serif;
    background: none !important;
    font-size: 36px;
    text-shadow: rgb(0, 0, 0) 3px 0px 3px;
}

.image-gallery-icon:hover {
    color: var(--color-primary);
}

.image-gallery-icon:focus {
    outline: none !important;
}

.image-gallery-thumbnails-container {
    display: none;
}

.fullscreen .image-gallery-thumbnails-container {
    display: block;
}

#welcome {
    padding-top: 86px;
    width: 60%;
    margin: auto;
}

#welcome .title {
    margin-bottom: 2rem;
}

#welcome .text {
    text-align: justify;
    text-align-last: center;
    margin: 1rem 0 !important;
    color: gray;
}

#welcome .text b {
    color: var(--color-primary);
}

#classes {
    background-color: var(--color-light);
}

.class-card {
    max-width: 80%;
    overflow: visible !important;
    margin: 3rem auto;
    padding: 1rem;
    box-shadow:
        0.6px 0.6px 2.2px rgba(105, 168, 141, 0.141),
        1.3px 1.3px 5.3px rgba(105, 168, 141, 0.202),
        2.5px 2.5px 10px rgba(105, 168, 141, 0.25),
        4.5px 4.5px 17.9px rgba(105, 168, 141, 0.298),
        8.4px 8.4px 33.4px rgba(105, 168, 141, 0.359),
        20px 20px 80px rgba(105, 168, 141, 0.5)
        !important;
}

/* ----- Media Queries (tablets) ----- */
@media screen and (max-width: 1024px) {
}

/* ----- Media Queries (phones) ----- */
@media screen and (max-width: 600px) {
}