#testimonials {
    padding: 0;
    margin-top: 4rem;
}

#testimonials .title {
    margin-bottom: 3rem;
}

.texts-container, .vids-container {
    height: auto;
    width: 80%;
    margin: auto;
    overflow: visible !important;
}

.texts-container {
    margin-bottom: 6rem;
}

.vids-container {
    margin-bottom: 2rem;
}

.testimonial {
    max-width: 80%;
    margin: auto;
    padding: 2rem;
    box-shadow:
        0.6px 0.6px 2.2px rgba(0, 0, 0, 0.02),
        1.3px 1.3px 5.3px rgba(0, 0, 0, 0.028),
        2.5px 2.5px 10px rgba(0, 0, 0, 0.035),
        4.5px 4.5px 17.9px rgba(0, 0, 0, 0.042),
        8.4px 8.4px 33.4px rgba(0, 0, 0, 0.05),
        20px 20px 80px rgba(0, 0, 0, 0.07)
        !important;

}

.testimonial .comments {
    text-align: center;
    font-style: italic;
    color: gray;
    padding-bottom: 2rem;
}

.testimonial .user-name {
    text-align: center;
    color: var(--color-primary);
}